import React from "react";
import Img from "gatsby-image/index"
import { graphql, StaticQuery } from "gatsby";

// TODO: overthink maxWidth
const Image = (props) => {
    return <StaticQuery
        query={graphql`
            query {
                images: allFile {
                    edges {
                        node {
                            relativePath
                            name
                            childImageSharp {
                                fluid(maxWidth: 800) {
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={data => {
            const image = data.images.edges.find(n => {
                return n.node.relativePath.includes(props.filename);
            });
            if (!image) {
                return null;
            }
            return <Img
                    alt={props.alt}
                    fluid={image.node.childImageSharp.fluid}
                    imgStyle={{ objectFit: "contain" }}
                    style={{ height: "100%", width: "100%" }}
            />
        }}
    />
}

export default Image