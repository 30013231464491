import { Link } from "gatsby"
import React, {useRef} from "react"
import gsap from "gsap";
import Image from "./image";
import headerStyles from "./header.module.css";

const Header = () => {

    let navbarIsOpened = true;
    let link1 = useRef(null);
    let link2 = useRef(null);
    let link3 = useRef(null);
    let link4 = useRef(null);
    let link5 = useRef(null);
    let link6 = useRef(null);
    let info = useRef(null);

    function animateNavbar() {
        if(navbarIsOpened) {
            staggerText(link1, link2, link3, link4, link5, link6);
            gsap.from(info, {
                duration: 0.8,
                opacity: 0,
                x: 20,
                delay: 0.8,
            })
        }

        navbarIsOpened = !navbarIsOpened;
    }

    const staggerText = (node1, node2, node3, node4, node5, node6) => {
        gsap.from([node1, node2, node3, node4, node5, node6], {
            duration: 0.8,
            y: 30,
            opacity: 0,
            delay: 0.1,
            ease: "power3.inOut",
            stagger: {
                amount: 0.2
            }
        })
    }

    return (
        <header>
            <div className={headerStyles.innerHeader}>
                <div style={{width: '150px', marginLeft: '5px'}}>
                    <Link to="/"><Image  alt="Logo" filename="logo.png" /></Link>
                </div>

                <input type="checkbox" id="toggle" className={headerStyles.toggle} style={{display: "none"}} />
                <label className={headerStyles.menuIcon} htmlFor="toggle" onClick={animateNavbar}>
                    <div></div>
                    <div></div>
                    <div></div>
                </label>

                <nav className={headerStyles.navContainer}>
                    <div className={headerStyles.navContent}>
                        <ul className={headerStyles.navLinks}>
                            <li ref={el => (link1 = el)}><Link to="/honigsorten/">Honigsorten</Link></li>
                            <li ref={el => (link2 = el)}><Link to="/produkte/">Produkte</Link></li>
                            <li ref={el => (link3 = el)}><Link to="/catering/">Catering</Link></li>
                            <li ref={el => (link4 = el)}><Link to="/labestation/">Labestation</Link></li>
                            <li ref={el => (link5 = el)}><Link to="/ueber-uns/">Über Uns</Link></li>
                            <li ref={el => (link6 = el)}><Link to="/kontakt/">Kontakt</Link></li>
                        </ul>
                        <div className={headerStyles.navInfo} ref={el => (info = el)}>
                            <p>
                                Täglich geöffnet ab 14 Uhr<br/>
                                Kontaktieren Sie uns unter:
                            </p>
                            <a href="tel:+436645726615" className="textLink">+43(0) 664 5726615</a>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header