/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import "./layout.css"
import "fontsource-montserrat"
import "fontsource-montserrat/500.css"
import "fontsource-montserrat/700.css"
import "fontsource-calistoga"

const Layout = ({ children }) => {
  const linkStyle = {
      textDecoration: 'none',
      color: 'black',
      fontWeight: 600,
      fontSize: '0.8rem',
      textTransform: 'uppercase'
  }
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header />
      <div
        style={{ padding: `80px 0 5px 0`, height: '100%' }} >
        <main style={{ zIndex: -1, minHeight: '100%', paddingBottom: '120px' }}>{children}</main>
        <footer style={{
          textAlign: 'center', height: '50px', marginTop: '-60px'
        }}>
          © {new Date().getFullYear()} Maierhofer Honigprodukte <br/>
            <Link to="/impressum" style={linkStyle}>Impressum</Link>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
